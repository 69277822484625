





































import Vue from "vue";
import { mapState } from "vuex";
import auth from "@/modules/auth"

export default Vue.extend({
  computed: {
    ...mapState(["textFields"]),
  },
  methods: {
    textSize(plus: boolean) {
      const htmlElement = document.querySelector("html");
      if (htmlElement) {
        if (!htmlElement.style.fontSize) {
          htmlElement.style.fontSize = plus ? "1.1rem" : "1rem";
        } else {
          let parseSize = parseFloat(
            htmlElement.style.fontSize.replace("rem", "")
          );
          if (plus) {
            if (parseSize < 3) {
              parseSize += 0.2;
            }
          } else {
            if (parseSize > 1) {
              parseSize = 1;
            }
          }
          htmlElement.style.fontSize = `${parseSize}rem`;
        }
      }
    },
    logout(){
      auth.logout()
      this.$router.push('/login')
    }
  },
});
