























import Vue from "vue";
import Header from "@/components/Header.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import { mapState } from "vuex";
import failsafe from "@/modules/failsafe";
import request from "./modules/request";

export default Vue.extend({
  components: {
    Header,
    NavDrawer,
  },
  name: "App",
  computed: {
    ...mapState(["admin", "alert"]),
  },
  data: () => ({
    failsafe: false,
    //
  }),
  async beforeMount() {
    // this.failsafe = await failsafe("cXVlcmN5Y2F1c3NhZGFpcw==");
    // console.clear();
    // If user is logged, we need to initialize axios
    if (this.$store.state.admin.isLogged) {
      // Do nothing
      if (!request.initialized) {
        request.initialize();
      }
    }
  },
});
