import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import request from "@/modules/request";
import { AxiosInstance } from "axios";
import Vue from "vue";
import Vuex from "vuex";
import Admin from "./interfaces/Admin";
import Category from "./models/Category";
import Tag from "./models/Tag";
import Coach from "./models/Coach"
import Video from "./models/Video";
import BodyPart from "./models/BodyPart"
import Pack from "./models/Pack"
import Company from "./models/Company";
import Series from "./models/Series";
import BlogArticle from "./models/BlogArticle";
import MotivationMessage from "./models/MotivationMessage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: {
      isLogged: false,
      token: "",
      uuid: "",
      superAdmin: false,
      expires: 0,
      userId: 0,
    },
    alert: {
      message: "",
      color: "",
      show: false,
    },
    videos: [] as Array<Video>,
    videoWatcher: 0,
    categories: {
      health: [] as Array<Category>,
      move: [] as Array<Category>,
      blog: [] as Array<Category>,
    },
    admins: [] as Array<Admin>,
    tags: [] as Array<Tag>,
    coaches: [] as Array<Coach>,
    bodyParts: [] as Array<BodyPart>,
    packs: [] as Array<Pack>,
    companies: [] as Array<Company>,
    series: [] as Array<Series>,
    blogArticles: [] as Array<BlogArticle>,
    motivationMessages: [] as Array<MotivationMessage>
  },
  mutations: {
    ADMIN(state, payload) {
      state.admin = payload;
    },
    ADMINS(state, payload) {
      state.admins = [...payload];
    },
    ALERT(state, payload) {
      state.alert = { ...payload };
    },
    VIDEOS(state, payload: Array<Video>) {
      state.videos = payload;
    },
    VIDEO_PROGRESS(state, payload: { index: number, progress: number, encoded: boolean, needsEncoding: boolean }) {
      state.videos[payload.index].encodingProgress = payload.progress;
      state.videos[payload.index].encoded = payload.encoded;
      state.videos[payload.index].needsEncoding = payload.needsEncoding;
    },
    VIDEO_WATCHER(state, payload: number) {
      state.videoWatcher = payload;
    },
    CATEGORIES(state, payload: { data: Category[], type: string }) {
      state.categories[payload.type as keyof (typeof state.categories)] = payload.data
    },
    TAGS(state, payload: Tag[]) {
      state.tags = payload
    },
    COACHES(state, payload: Coach[]) {
      state.coaches = payload
    },
    BODYPARTS(state, payload: BodyPart[]) {
      state.bodyParts = payload
    },
    PACKS(state, payload: Pack[]) {
      state.packs = payload
    },
    COMPANIES(state, payload: Company[]) {
      state.companies = payload
    },
    SERIES(state, payload: Series[]) {
      state.series = payload
    },
    BLOGARTICLES(state, payload: BlogArticle[]) {
      state.blogArticles = payload;
    },
    MOTIVATIONMESSAGES(state, payload: MotivationMessage[]) {
      state.motivationMessages = payload;
    }
  },
  actions: {
    showAlert(
      context,
      payload: { message: string; color: string; timeout: number }
    ) {
      context.commit("ALERT", {
        message: payload.message,
        color: payload.color,
        show: true,
      });
      setTimeout(() => {
        context.commit("ALERT", {
          message: payload.message,
          color: payload.color,
          show: false,
        });
        setTimeout(() => {
          context.commit("ALERT", { message: "", color: "", show: false });
        }, 500);
      }, payload.timeout);
    },
    async getPacks(context) {
      try {
        const packs = await request.shared.get('pack');
        context.commit("PACKS", packs.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getAdmins(context) {
      try {
        const admins = await request.shared.get('admin');
        context.commit("ADMINS", admins.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getCompanies(context) {
      try {
        const companies = await request.shared.get('company');
        context.commit("COMPANIES", companies.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getBlogArticles(context) {
      try {
        const blogArticles = await request.shared.get('blogarticle');
        context.commit("BLOGARTICLES", blogArticles.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getMotivationMessages(context) {
      try {
        const motivationMessages = await request.shared.get('motivationmessage');
        context.commit("MOTIVATIONMESSAGES", motivationMessages.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getSeries(context) {
      try {
        const series = await request.shared.get('series');
        // If the series has a coverPic, we need to fetch it and store it
        // If there is none, we store the placeholder
        const seriesObject: Series[] = series.data
        for (const series of seriesObject) {
          if (!series.CoverPic) {
            series.coverPicSrc = base64Images.thumbnailPlaceholder
          }
          else {
            const blob = await request.shared.get(
              `content/${context.state.admin.uuid}/media/${series.CoverPic.filename}`,
              {
                responseType: "blob",
              }
            );
            series.coverPicSrc = URL.createObjectURL(blob.data);
          }
        }

        context.commit("SERIES", series.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getBodyParts(context) {
      try {
        const bodyparts = await request.shared.get('bodypart');
        context.commit("BODYPARTS", bodyparts.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getCategories(context, type: string) {
      try {
        const categories = await request.shared.get(`category/${type}`);
        context.commit('CATEGORIES', { data: categories.data, type })
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getTags(context) {
      try {
        const tags = await request.shared.get('tag');
        context.commit("TAGS", tags.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getCoaches(context) {
      try {
        const coaches = await request.shared.get('coach');
        // We get the formatted name for each Coach
        for (const coach of coaches.data as Coach[]) {
          coach.formattedName = `${coach.firstname} ${coach.lastname}`
        }
        // We need the array of thumbnails, which will be stored in base64 into memory
        for (const coach of coaches.data as Array<Coach>) {
          if (!coach.ProfilePic) {
            coach.profilePicSrc = base64Images.userPlaceholder;
          } else {
            const blob = await request.shared.get(
              `content/${context.state.admin.uuid}/media/${coach.ProfilePic.filename}`,
              {
                responseType: "blob",
              }
            );
            coach.profilePicSrc = URL.createObjectURL(blob.data);
          }
        }
        context.commit("COACHES", coaches.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getVideos(context) {
      try {
        const videos = await request.shared.request({
          url: 'video'
        });
        // We need the array of thumbnails, which will be stored in base64 into memory
        for (const video of videos.data as Array<Video>) {
          video.thumbnailSrc = base64Images.thumbnailPlaceholder;
        }
        context.commit('VIDEOS', videos.data)
        // Set encoding watcher if doesn't exist
        if (!context.state.videoWatcher) {
          console.log("setting video watcher")
          const index = setInterval(() => {
            context.dispatch('encodingWatcher');
          }, 10000);
          context.commit('VIDEO_WATCHER', index)
        }
      } catch (error) {
        console.log(error);
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async encodingWatcher(context) {
      console.log("watching progress")
      try {
        for (const index in context.state.videos) {
          // If the video isn't encoded and isn't damaged
          if (!context.state.videos[index].encoded && !context.state.videos[index].encodingError) {
            // We get the details and update the progress and encoded status
            const video = await Request.shared.get(`video/${context.state.videos[index].id}`)
            context.commit('VIDEO_PROGRESS', { index, progress: video.data.encodingProgress, encoded: video.data.encoded, needsEncoding: video.data.needsEncoding })
          }
        }
      } catch (error) {
        console.log(error);
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async refreshAdmins(context) {
      try {
        if (context.state.admin.isLogged && context.state.admin.token) {
          const route = `${process.env.VUE_APP_API_HOST}/admin`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);
          const response = await fetch(route, {
            headers,
          });
          if (response.status === 200) {
            context.commit("ADMINS", await response.json());
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async addAdmin(context, payload) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/admin`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);
        headers.set("Content-Type", "application/json");
        const response = await fetch(route, {
          headers,
          method: "POST",
          body: JSON.stringify(payload),
        });
        if (response.status === 201) {
          context.dispatch("showAlert", {
            message: "Administrateur ajouté avec succès",
            color: "success",
            timeout: 3000,
          });
          await context.dispatch("refreshAdmins");
        } else {
          context.dispatch("showAlert", {
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible d'ajouter un administrateur actuellement",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async deleteAdmin(context, payload: number) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/admin/${payload}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        const response = await fetch(route, {
          headers,
          method: "DELETE",
        });
        if (response.status === 200) {
          context.dispatch("showAlert", {
            message: "Administrateur supprimé avec succès",
            color: "success",
            timeout: 3000,
          });
          await context.dispatch("refreshAdmins");
        } else {
          context.dispatch("showAlert", {
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible de supprimer cet administrateur",
          color: "warning",
          timeout: 4000,
        });
      }
    },
  },
  modules: {},
});
