











































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["admin"]),
  },
  data: () => ({
    menu: {
      top: [
       // { name: "Dashboard", icon: "mdi-view-dashboard", link: "/" },
        { name: "Entreprises", icon: "mdi-domain", link: "/companies" },
        { name: "Coachs", icon: "mdi-weight-lifter", link: "/coaches" },
        { name: "Vidéos", icon: "mdi-video", link: "/videos" },
        {
          name: "Catégories",
          icon: "mdi-folder-multiple",
          link: "/categories",
        },
        {
          name: "Séries",
          icon: "mdi-filmstrip-box-multiple",
          link: "/series",
        },
        { name: "Blog", icon: "mdi-post", link: "/blog" },
      ],
      bottom: [
        { name: "Statistiques", icon: "mdi-chart-pie", link: "/statistics" },
     //   { name: "Paramètres", icon: "mdi-cog", link: "/settings" },
        {
          name: "Administrateurs",
          icon: "mdi-shield-account",
          link: "/admins",
        },
      ],
      superAdmin: [
        { name: "Packs", icon: "mdi-package", link: "/packs" },
        {
          name: "Messages",
          icon: "mdi-android-messages",
          link: "/motivationmessages",
        },
      ],
    },
  }),
});
