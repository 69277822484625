import axios, { AxiosInstance, AxiosResponse } from "axios";
import store from "@/store";

export default class Request {
    static shared: AxiosInstance = axios.create();
    static initialized = false;

    static initialize() {
        Request.shared = axios.create({
            baseURL: process.env.VUE_APP_API_HOST,
            headers: {
                'Authorization': `Bearer ${store.state.admin.token}`
            }
        })
        Request.initialized = true;
    }
    static async postVideo(file: File, body: Record<string, any>, progressCallback: (e: ProgressEvent) => void, successCallback: (response: AxiosResponse) => void, errorCallback?: (error: Error) => void) {
        try {
            const formData = new FormData();
            formData.append('body', JSON.stringify(body))
            formData.append('video', file)
            const response = await Request.shared.request({
                method: 'POST',
                url: "video",
                data: formData,
                onUploadProgress: progressCallback
            })

            if (response.status == 201) {
                successCallback(response);
            }
        } catch (error) {
            if(errorCallback){
                errorCallback((error as any).response.data);
            }
        }
    }
}