import Vue from 'vue'
import VueRouter, { RouteConfig, NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import auth from "@/modules/auth";
import request from '@/modules/request';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/companies',
    component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue')
  },
  {
    path: '/coaches',
    component: () => import(/* webpackChunkName: "coaches" */ '../views/Coaches.vue')
  },
  {
    path: '/videos',
    component: () => import(/* webpackChunkName: "videos" */ '../views/Videos.vue')
  },
  {
    path: '/categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue')
  },
  {
    path: '/series',
    component: () => import(/* webpackChunkName: "series" */ '../views/Series.vue')
  },
  {
    path: '/blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
  {
    path: '/statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue')
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/admins',
    component: () => import(/* webpackChunkName: "admins" */ '../views/Admins.vue')
  },
  {
    path: '/packs',
    component: () => import(/* webpackChunkName: "packs" */ '../views/Packs.vue'),
    beforeEnter: isSuperAdmin
  },
  {
    path: '/motivationmessages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/MotivationMessages.vue'),
    beforeEnter: isSuperAdmin
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Navigation guards
router.beforeEach(async (to, from, next) => {
  // Before each route, we need to check if our token is still valid
  if (store.state.admin.isLogged) {
    // Do nothing
    if (!request.initialized) {
      request.initialize();
    }
  } else {
    const localAdminInfos = auth.parseLocalStorage()
    //
    if (localAdminInfos) {
      // We need to store login infos and initialize the axios request
      auth.storeLogin(localAdminInfos.token, localAdminInfos.userId, localAdminInfos.uuid, localAdminInfos.superAdmin)
      console.log("initializing axios")
      request.initialize();
    }
  }

  // If we don't go to the login page, we need to check if we're logged, if not, go to login :
  if (to.path !== "/login") {
    if (!store.state.admin.isLogged) {
      next('/login')
    } else {
      next();
    }
  }
  else {
    auth.logout();
    next();
  }
})

function isSuperAdmin(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (store.state.admin.superAdmin) {
    next();
  } else {
    next(false);
  }
}

export default router
