









import Vue from "vue";

export default Vue.extend({
  name: "Home",
  beforeMount(){
    this.$router.push("/companies")
  }
});
